import { Controller } from "@hotwired/stimulus";
import Trix from "trix";

export default class extends Controller {
  static UNUSED_TOOLBAR_CLASSES = [
    ".trix-button--icon-strike",
    ".trix-button--icon-heading-1",
    ".trix-button--icon-quote",
    ".trix-button--icon-code",
    ".trix-button--icon-decrease-nesting-level",
    ".trix-button--icon-increase-nesting-level",
    ".trix-button-group-spacer"
  ];

  connect() {
    this.setupTrix()
    this.configureToolbar()
  }

  setupTrix() {
    Trix.config.textAttributes.underline = {
      tagName: "u",
      style: { textDecoration: "underline" },
      inheritable: true,
      parser: function (element) {
        var style = window.getComputedStyle(element);
        return style.textDecoration === "underline";
      },
    };
  }

  configureToolbar() {
    addEventListener("trix-initialize", (event) => {
      let toolbarElement = event.target.toolbarElement;

      this.addUnderlineButton(toolbarElement);
      this.removeUnusedItems(toolbarElement);
    }, true);
  }

  addUnderlineButton(toolbarElement) {
    if (toolbarElement.querySelector(".trix-button--icon-underline")) {
      return;
    }

    let underlineEl = document.createElement("button");
    underlineEl.setAttribute("type", "button");
    underlineEl.setAttribute("data-trix-attribute", "underline");
    underlineEl.setAttribute("data-trix-key", "u");
    underlineEl.setAttribute("tabindex", -1);
    underlineEl.setAttribute("title", "underline");
    underlineEl.classList.add("trix-button", "trix-button--icon", "trix-button--icon-underline");
    underlineEl.innerHTML = "U";

    const textToolsGroup = toolbarElement.querySelector(".trix-button-group--text-tools");
    textToolsGroup.insertBefore(underlineEl, textToolsGroup.children[2]);
  }

  removeUnusedItems(toolbarElement) {
    this.constructor.UNUSED_TOOLBAR_CLASSES.forEach((cls) => {
      if (toolbarElement.querySelector(cls)) {
        toolbarElement.querySelector(cls).remove();
      }
    });
  }
}
