import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "multichoice", "rating", "email", "responseTypeLabel", "responseType", "notificationSection", "ratingSection", "optionsSection", "questionContent", "sectionHeader" ]

  connect() {
    this.updateQuestion()
  }

  updateQuestion() {
    if (!this.hasResponseTypeLabelTarget) {
      return;
    }

    this.updateResponseTypeLabel()
    this.hideNotificationSection()
    this.hideRatingSection()
    this.hideOptionsSection()

    if (this.ratingQuestion()) {
      this.showRatingSection()
    } else if (this.multichoiceQuestion()) {
      this.showOptionsSection()
    }

    if (this.notificationQuestion()) {
      this.showNotificationSection()
    }
  }

  showRatingSection() {
    this.ratingSectionTarget.classList.remove("hidden")
  }

  showOptionsSection() {
    this.optionsSectionTarget.classList.remove("hidden")
  }

  showNotificationSection() {
    this.notificationSectionTarget.classList.remove("hidden")
  }

  hideRatingSection() {
    this.ratingSectionTarget.classList.add("hidden")
  }

  hideOptionsSection() {
    this.optionsSectionTarget.classList.add("hidden")
  }

  hideNotificationSection() {
    this.notificationSectionTarget.classList.add("hidden")
  }

  updateResponseTypeLabel() {
    this.responseTypeLabelTarget.innerHTML = this.selectedResponseType();
  }

  multichoiceQuestion() {
    return this.selectedResponseType() == this.multichoiceTarget.value
  }

  ratingQuestion() {
    return this.selectedResponseType() == this.ratingTarget.value
  }

  emailQuestion() {
    return this.selectedResponseType() == this.emailTarget.value
  }

  notificationQuestion() {
    return this.multichoiceQuestion() || this.emailQuestion()
  }

  selectedResponseType() {
    return this.responseTypeTarget.options[this.responseTypeTarget.selectedIndex].text
  }

  updateSectionHeader() {
    this.sectionHeaderTarget.innerHTML = this.questionContentTarget.value
  }
}
