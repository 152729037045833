import { Controller } from "@hotwired/stimulus";
import interact from "interactjs";

export default class extends Controller {
  connect() {
    this.dragMoveListener = this.dragMoveListener.bind(this);
    this.setupResizeDrag();
   }

  setupResizeDrag() {
    interact('.drag-element')
      .resizable({
        // resize from all edges and corners
        edges: { left: true, right: true, bottom: true, top: false },
        listeners: {
          move: (event) => {
            let target = event.target;
            let x = (parseFloat(target.getAttribute('data-x')) || 0);
            let y = (parseFloat(target.getAttribute('data-y')) || 0);

            // update the element's style
            target.style.width = event.rect.width + 'px';
            target.style.height = event.rect.height + 'px';

            // translate when resizing from top or left edges
            x += event.deltaRect.left;
            y += event.deltaRect.top;

            target.style.transform = `translate(${x}px, ${y}px)`;

            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);

            this.updateHiddenFieldsFor(target, x, y, event.rect.width, event.rect.height);
          }
        },
        modifiers: [
          interact.modifiers.restrictEdges({ outer: '.canvas' }),
          interact.modifiers.restrictSize({ min: { width: 150, height: 30 } })
        ],
        inertia: true
      })
      .draggable({
        listeners: { move: this.dragMoveListener },
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: '.canvas',
            endOnly: true
          })
        ]
      });
  }

  dragMoveListener(event) {
    let target = event.target;
    let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
    let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

    target.style.transform = `translate(${x}px, ${y}px)`;

    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);

    this.updateHiddenFieldsFor(target, x, y);
  }

  updateHiddenFieldsFor(target, x = null, y = null, width = null, height = null) {
    const prefix = target.getAttribute('data-prefix');

    if (x !== null) {
        document.querySelector(`input[id='${prefix}_x']`).value = x;
    }
    if (y !== null) {
        document.querySelector(`input[id='${prefix}_y']`).value = y;
    }
    if (width !== null) {
        document.querySelector(`input[id='${prefix}_width']`).value = width;
    }
    if (height !== null) {
        document.querySelector(`input[id='${prefix}_height']`).value = height;
    }
  }
}
